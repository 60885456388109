<script>
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'

export default {
  name: 'base-back',
  components: {
    BaseButton,
    BaseIcon,
  },
  props: {
    to: {
      type: Object,
      default: null,
    },
    showText: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onClick() {
      if (this.to) {
        this.$router.push(this.to)
      } else {
        this.$router.back()
      }

      this.$emit('click')
    },
  },
}
</script>

<template>
  <base-button look="link" @click="onClick">
    <template #icon>
      <base-icon svg="base/chevron-bold" :size="12" class="mr-2.5" />
      <span v-if="showText" class="text-base">
        <slot>Back</slot>
      </span>
    </template>
  </base-button>
</template>
